import React,{Component} from 'react';
import './App.css';

class App extends Component{
  render(){
  return (
    <div className="App">
      <header className="App-header">
        <img src="/images/download.png" className="App-logo" alt="hello" />
        <p>
          To Know more about it click on Aipalbot link below
        </p>
        <a
          className="App-link"
          href="https://www.aipalbot.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          AIPALBOT
        </a>
      </header>

      
    </div>
  );
}
}
export default App;
